// import Spruce from '@/modules/@ryangjchandler/spruce/dist/spruce';
import { subscribe } from '@/utils/event-bus';

// const store = Spruce.store('useCase/show', {
//   selectedSleeve: null,
//   hasMachines: false,
//   hasSpecialtyMachines: false,
// });

function show() {
  return {
    states: [],
    selectedSleeve: null,
    hasMachines: false,
    hasSpecialtyMachines: false,
    init() {
      subscribe('useCase/show:slideChange', (swiper: any) => {
        let index_currentSlide = swiper.activeIndex;
        let currentSlide = swiper.slides[index_currentSlide];
        this.selectedSleeve = currentSlide.dataset.sleeve;
        this.hasMachines = currentSlide.dataset.machines > 0;
        this.hasSpecialtyMachines = currentSlide.dataset.specialtyMachines > 0;
      });
    },
  };
}

(window as any)['useCase/show'] = show;
