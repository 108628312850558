import objectFitImages from 'object-fit-images';

function image() {
  return {
    states: [],
    init() {
      const image = (this as any).$el;
      objectFitImages(image, { watchMQ: true });
    },
  };
}

(window as any).image = image;
