import Ready from '@/utils/ready';
const selector = '[data-sticky="true"]';

(() => {
  window.addEventListener('DOMContentLoaded', () => {
    Ready.watch(selector, (element: HTMLElement) => {
      if (!element.dataset.stickyInitialized) {
        element.setAttribute('data-sticky-initialized', 'true');
        import('hc-sticky').then((module) => {
          const getOffset = () => {
            const navEl: HTMLElement | null =
              document.querySelector('.navigation');
            const navOffset =
              navEl && navEl !== element ? navEl.offsetHeight : 0;
            return navOffset.toString();
          };
          const Sticky = module.default;
          const instance = new Sticky(element, {
            top: getOffset(),
            stickyClass: 'hc-sticky',
          });
          window.addEventListener('resize', () => {
            instance.update({
              top: getOffset(),
            });
          });
        });
      }
    });
  });
})();
