document.addEventListener('DOMContentLoaded', () => {
  import('./scroller').then((asyncModule) => {
    (window as any).Scroller = new asyncModule.default();
    if (window.location.hash) {
      const element = document.querySelector(window.location.hash);
      if (element) {
        setTimeout(() => {
          (window as any).Scroller.scrollTo(element);
        }, 1500);
      }
    }
  });
});
