/**
 * subscriptions data format:
 * { type: { id: callback } }
 */
const subscriptions: any = {};
const getNextUniqueId = getIdGenerator();

export const subscribe = (type: string, callback: any) => {
  const id = getNextUniqueId();

  if (!subscriptions[type]) subscriptions[type] = {};

  subscriptions[type][id] = callback;

  return {
    unsubscribe: () => {
      delete subscriptions[type][id];
      if (Object.keys(subscriptions[type]).length === 0)
        delete subscriptions[type];
    },
  };
};

export const publish = (type: string, payload: any = null) => {
  if (!subscriptions[type]) return;

  Object.keys(subscriptions[type]).forEach((key) =>
    subscriptions[type][key](payload),
  );
};

function getIdGenerator() {
  let lastId = 0;

  return function getNextUniqueId() {
    lastId += 1;
    return lastId;
  };
}
