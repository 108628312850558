import Ready from '@/utils/ready';

const init = () => {
  Ready.watch('[data-lightgallery]', (element: HTMLElement) => {
    import('./light-gallery').then((asyncModule: any) => {
      new asyncModule.default(element);
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
