function accordion() {
  return {
    states: [],
    selected: '',
    options: {},
    init(options: any) {
      this.options = options;
    },
    setSelected(id: string): void {
      this.selected !== id ? (this.selected = id) : (this.selected = '');
    },
    getComputedStyle(id: string, ref: string): string {
      return this.selected === id
        ? 'max-height: ' + this.$refs[ref].scrollHeight + 'px !important;'
        : '';
    },
    onWindowResize(id: string, ref: string): void {
      if (this.selected === id) {
        this.$refs[ref].style.setProperty(
          'max-height',
          this.$refs[ref].scrollHeight + 'px',
          'important',
        );
      }
    },
  };
}

(window as any).accordion = accordion;
