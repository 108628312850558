import { Loader } from '@googlemaps/js-api-loader';
import MarkerClusterer from '@googlemaps/markerclustererplus';
// @ts-ignore
import { v4 as uuid } from 'uuid';

function mapInstance(): any {
  const maps: any = {};
  return {
    uuid: uuid(),
    sites: [],
    activeSite: {
      name: '',
      address: '',
      lat: 0,
      lng: 0,
      contacts: [],
    },
    showSite: false,
    defaultZoom: 2,
    init({ sites }: any) {
      maps[this.uuid] = null;
      this.sites = sites;
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMAPS_KEY,
        version: 'weekly',
      });
      loader.load().then(() => {
        this.createMap();
      });
    },
    createMap() {
      const mapStyles: any = [
        {
          featureType: 'administrative',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'all',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'administrative.country',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'administrative.province',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#d6d6d6',
            },
          ],
        },
        {
          featureType: 'landscape.natural',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [
            {
              color: '#cccccc',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.station.airport',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit.station.airport',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#FFFFFF',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ];
      const mapOptions = {
        center: {
          lat: 20,
          lng: 0,
        },
        zoom: this.defaultZoom,
        styles: mapStyles,
      };
      const svgMarker = {
        path: 'M15.3523 0.615479C7.25826 0.615479 0.67334 7.2004 0.67334 15.2944C0.67334 19.2263 3.05544 24.2755 7.75345 30.3012C9.90583 33.0548 12.2367 35.664 14.7311 38.112C14.8983 38.272 15.1208 38.3613 15.3523 38.3613C15.5837 38.3613 15.8062 38.272 15.9734 38.112C18.4678 35.664 20.7987 33.0548 22.9511 30.3012C27.6491 24.2755 30.0312 19.2263 30.0312 15.2944C30.0312 7.2004 23.4463 0.615479 15.3523 0.615479ZM15.3523 25.7793C9.57056 25.7793 4.86732 21.0761 4.86732 15.2944C4.86732 9.5127 9.57056 4.80946 15.3523 4.80946C21.134 4.80946 25.8372 9.5127 25.8372 15.2944C25.8372 21.0761 21.134 25.7793 15.3523 25.7793Z',
        fillColor: '#007BC8',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.7,
        anchor: new google.maps.Point(15.5, 39),
      };
      maps[this.uuid] = new google.maps.Map(
        (this as any).$refs.mapContainer,
        mapOptions,
      );
      const map = maps[this.uuid];
      const markers = this.sites.map((site: any) => {
        const marker = new google.maps.Marker({
          position: {
            lat: site.lat,
            lng: site.lng,
          },
          title: site.name,
          icon: svgMarker,
          animation: google.maps.Animation.DROP,
          // @ts-ignore
          site,
        });
        this.onMarkerClick(marker, map);
        return marker;
      });
      new MarkerClusterer(map, markers, {
        styles: [
          {
            width: 50,
            height: 50,
            className: 'clusterIcon',
          },
        ],
        minimumClusterSize: 100,
      });
    },
    onCardClose() {
      this.showSite = false;
      maps[this.uuid].setZoom(this.defaultZoom);
    },
    onMarkerClick(marker: any, map: any) {
      marker.addListener('click', () => {
        map.setZoom(14);
        map.setCenter(marker.getPosition());
        this.activeSite = marker.site;
        this.showSite = true;
      });
    },
  };
}

(window as any).mapInstance = mapInstance;
