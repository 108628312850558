const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};

const unit = {
  sm: '37px',
  base: '54px',
  md: '54px',
};

const spacing = {
  base: '15px',
  xs: '6px',
  sm: '10px',
  md: '15px',
  lg: '30px',
  xl: '60px',
  '2xl': '80px',
};

const pristineConfig = {
  unit,
  screens,
  components: {
    navigation: {
      height: {
        sm: '90px',
        base: '122px',
      },
    },
    submenu: {
      height: {
        base: '54px',
      },
    },
  },
  bootstrapBreakpoints: {
    xxs: 0,
    xs: '500px',
    ...screens,
  },
  bootstrapMaxWidths: screens,
  gutters: {
    ...spacing,
  },
  colors: {
    primary: {
      base: '#007BC8',
    },
    secondary: {
      base: '#FC4C02',
    },
    blue: {
      base: '#0118e6',
      dark: '#0113b8',
    },
    gray: {
      dark: '#000314',
      base: '#444B51',
      neutral: '#68757F',
      medium: '#D6D6D6',
      light: '#EBEBEB',
      lightest: '#F8F8F8',
    },
    twitter: { base: '#0FB5EE' },
    linkedin: { base: '#0077B5' },
    facebook: { base: '#3b5998' },
    instagram: { base: '#30618A' },
    youtube: { base: '#FF0000' },
    success: { base: '#C7C414' },
    error: { base: '#C71415' },
  },
  spacing: {
    unit,
    ...spacing,
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {
    base: '11px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    sansPrimary: [
      'Avenir LT Std',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    sansSecondary: [
      'Avenir LT Std',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    sans: [
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: [
      'ui-serif',
      'Georgia',
      'Cambria',
      '"Times New Roman"',
      'Times',
      'serif',
    ],
    mono: [
      'ui-monospace',
      'SFMono-Regular',
      'Menlo',
      'Monaco',
      'Consolas',
      '"Liberation Mono"',
      '"Courier New"',
      'monospace',
    ],
  },
  fontSize: {
    base: '17px',
    sm: '14px',
    md: '17px',
    lg: '18px',
    xl: '24px',
    '2xl': '30px',
    '3xl': '34px',
    '4xl': '48px',
  },
  fontWeight: {},
  height: {
    unit,
  },
  inset: {},
  letterSpacing: {
    lg: '2px',
  },
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {
    unit,
  },
  maxWidth: {
    unit,
  },
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {
    unit,
  },
  zIndex: {},
  transitionDuration: {
    base: '200ms',
  },
  transitions: {
    base: 'all .2s ease-in-out',
    fast: 'all .1s ease-in-out',
  },
};

module.exports = pristineConfig;
