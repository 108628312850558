import Cookies from 'js-cookie';
import { storedCookies, storedCookiesName } from '@/modules/cookies';
import { initGA } from '@/modules/analytics';

function cookies() {
  return {
    states: [],
    open: storedCookies !== 'true',
    refuseCookies() {
      Cookies.set(storedCookiesName, 'false', { expires: 365 });
      this.open = false;
    },
    acceptCookies() {
      Cookies.set(storedCookiesName, 'true', { expires: 365 });
      this.open = false;
      initGA(process.env.VUE_APP_GA_ID, process.env.VUE_APP_GTM_ID);
    },
    acceptCookiesByDefault() {
      if (storedCookies !== 'true') {
        Cookies.set(storedCookiesName, 'true', { expires: 365 });
        initGA(process.env.VUE_APP_GA_ID, process.env.VUE_APP_GTM_ID);
      }
    },
  };
}

if (storedCookies === 'true') {
  initGA(process.env.VUE_APP_GA_ID, process.env.VUE_APP_GTM_ID);
}

(window as any).cookies = cookies;
