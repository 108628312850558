import { orderBy } from 'lodash';
import { getNavigationOffset } from '@/utils/app';
// import Spruce from '@/modules/@ryangjchandler/spruce/dist/spruce';

// This doesn't work on IE
// const store = Spruce.store('submenu', {
//   currentSection: '',
//   currentSectionItem: {},
//   links: [],
// });

function submenu() {
  return {
    states: [],
    links: [],
    offset: 0,
    currentSection: '',
    currentSectionItem: {},
    init(links: any, $dispatch: any, offset: number = 0) {
      this.links = links;
      this.offset = offset;
      // Alternative to store
      (this as any).$watch('links', (value: any) =>
        $dispatch('submenu-links', value),
      );
      (this as any).$watch('currentSection', (value: any) =>
        $dispatch('submenu-current-section', value),
      );
      (this as any).$watch('currentSectionItem', (value: any) =>
        $dispatch('submenu-current-section-item', value),
      );
      this.updateCurrentSectionItem();
    },
    getCurrentSection() {
      const offset = getNavigationOffset();
      const els = this.links
        .map((link: any) => ({
          link,
          el: document.querySelector('#' + link.slug),
        }))
        .filter((item) => item.el);

      let pos: any[] = els
        .map((item: any) => ({
          ...item,
          top:
            this.offset +
            item.el.getBoundingClientRect().top +
            item.el.ownerDocument.defaultView.pageYOffset -
            window.scrollY -
            1,
        }))
        .filter((item) => item.top < offset);
      pos = orderBy(pos, ['top'], ['desc']);
      return pos.length > 0 ? pos[0].link.slug : '';
    },
    updateCurrentSectionItem() {
      this.currentSectionItem =
        this.links.find((link: any) => link.slug === this.currentSection) ||
        this.links[0] ||
        {};
    },
    onScroll(e: any) {
      const currentSection = this.getCurrentSection();
      this.currentSection = currentSection;
      this.updateCurrentSectionItem();
    },
  };
}

(window as any).submenu = submenu;
