function card() {
  return {
    states: [],
    selected: '',
    getRefMaxHeight(ref: string): string {
      return this.states.indexOf('hover') > -1
        ? this.$refs[ref].scrollHeight
        : 0;
    },
  };
}

(window as any).card = card;
