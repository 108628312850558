import Iodine from '@kingshott/iodine';

function formItemInstance() {
  return {
    iodine: new Iodine(),
    formEl: null,
    inputEl: null,
    formItem: {},
    errorMessage: '',
    labelVisible: true,
    blurred: true,
    init() {
      this.formEl = this.$refs.formItem.closest('form');
      this.inputEl = this.$refs.formItemInput.children[0];
      const errorMessages = (this.formEl as any).parentElement.dataset
        .formErrorMessages;
      this.iodine.setErrorMessages(
        errorMessages ? JSON.parse(errorMessages) : {},
      );
      // fix for input wrapped by alpine due to the spruce workaround
      if (this.inputEl && this.inputEl.tagName === 'DIV') {
        this.inputEl = this.inputEl.children[0];
      }
      this.addEventListener();
    },
    addEventListener() {
      const { inputEl, formEl } = this as any;
      if (inputEl) {
        inputEl.addEventListener('input', () => {
          this.labelVisible = !!!inputEl.value;
          this.updateErrorMessage();
        });
        inputEl.addEventListener('focus', () => {
          this.blurred = false;
        });
        inputEl.addEventListener('blur', () => {
          this.blurred = true;
          this.updateErrorMessage();
        });
      }
      if (this.formEl) {
        formEl.addEventListener('submit', () => {
          this.updateErrorMessage();
        });
      }
      this.labelVisible = !!!inputEl.value;
    },
    updateErrorMessage() {
      this.errorMessage = this.getErrorMessage(this.inputEl);
    },
    getErrorMessage(input: HTMLInputElement | null) {
      const { iodine } = this;
      if (input) {
        const error: any = iodine.is(
          input.value,
          JSON.parse(this.$refs.formItem.dataset.rules),
        );
        if (error !== true && this.blurred) {
          return iodine.getErrorMessage(error);
        }
      }
      return '';
    },
  };
}

(window as any).formItemInstance = formItemInstance;
