/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';
import '@/polyfills/CustomProperties.min';
// import '@/modules/@ryangjchandler/spruce/dist/spruce';
import 'alpinejs/dist/alpine-ie11.js';

const ElementQueries = require('css-element-queries/src/ElementQueries');
//attaches to DOMLoadContent
ElementQueries.listen();
/* Add scrollbar-width variable for accurate calculations with calc */
document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/line.css';

/* Import Styles */
import '@/styles/main.scss';

/* Auto Imports */
const srcContext = require.context(
  '@',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);
