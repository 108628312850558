import Ready from '@/utils/ready';

const init = () => {
  Ready.watch('[data-overlayscrollbars]', (element: HTMLElement) => {
    import('./overlayscrollbars').then((asyncModule: any) => {
      new asyncModule.default(
        element,
        element.dataset.overlayscrollbarsOptions,
      );
    });
  });
};

document.addEventListener('DOMContentLoaded', init);
