import { defaultsDeep } from 'lodash';

function popover() {
  return {
    states: [],
    options: {
      trigger: 'click',
      interactive: true,
      arrow: false,
      placement: 'auto',
      appendTo: () => document.body,
      offset: [0, 0],
      animation: 'shift-toward',
      interactiveDebounce: 0,
    },
    init(options: any) {
      if (!options.disabled) {
        delete options.disabled;
        import('./popover').then((asyncModule: any) => {
          new asyncModule.default(this.$refs['reference'], {
            ...defaultsDeep(options, this.options),
            content: this.$refs['content'],
          });
          (this as any).$el.classList.remove('invisible');
        });
        return;
      }
      (this as any).$el.classList.remove('invisible');
    },
  };
}

(window as any).popover = popover;
